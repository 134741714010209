<template>
  <div class="body">
    <div class="fy_center_box">
      <img alt class="nofound_img" src="@/static/image/common/404.png"/>
    </div>
  </div>
</template>
<script>
export default {
  name: 'nofound'
}
</script>
<style lang="scss" scoped>
.body {
  width: 100%;
  height: 100%;

  .nofound_img {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 530px;
    height: 290px;
  }
}
</style>
